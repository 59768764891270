import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';
import { fonts } from './fonts';
import headerImage from '../assets/images/Tensure_PatternBackgrounds_Green_Purple.png';
import { black, cream, white, yellow, purple, green, red, blue } from './colors';

export const tensureLightTheme = createUnifiedTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': fonts,
        '@global': {
          body: {
            fontWeight: 350,
          },
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: {
          color: black,
        },
        value: {
          color: black,
        },
      },
    },
    PluginCatalogEntityContextMenu: {
      styleOverrides: {
        button: {
          color: black,
        },
      },
    },
    CatalogReactEntityDisplayName: {
      styleOverrides: {
        icon: {
          color: black,
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          width: 'auto',
          margin: '0px',
          boxShadow: 'none',
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          backgroundImage: `url(${headerImage})`, // Set the background image
          backgroundSize: 'cover', // Cover the entire header          
          color: black,
        }),
        title: {
          color: black,
          lineHeight: '1.5',
        },
        subtitle: {
          color: black,
        },
        type: {
          color: black,
        },
      },
    },
  },
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: black,
      },
      secondary: {
        main: cream,
      },
      error: {
        main: red,
      },
      warning: {
        main: yellow,
      },
      info: {
        main: blue,
      },
      success: {
        main: green,
      },
      background: {
        default: cream,
        paper: cream,
      },
      banner: {
        info: blue,
        error: red,
        text: black,
        link: blue,
      },
      header: {
        text: white,
      },
      errorBackground: red,
      warningBackground: yellow,
      infoBackground: blue,
      navigation: {
        background: black,
        indicator: green,
        color: white,
        selectedColor: purple,
      },
    },
    typography: {
      fontFamily: 'ABC Oracle',
      htmlFontSize: 16,
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
        fontFamily: 'Gooper',
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
        fontFamily: 'Gooper',
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
        fontFamily: 'Gooper',
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
        fontFamily: 'Gooper-Light-Italic',
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
        fontFamily: 'Gooper',
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
        fontFamily: 'Gooper',
      },
      
    }
  }),
  defaultPageTheme: 'home',
});