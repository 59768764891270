import React, { PropsWithChildren, useState, useCallback } from 'react';
import {
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';


export function Root({ children }: PropsWithChildren<{}>) {
  const [anchorElNav, setAnchorElNav] = useState<HTMLElement | null>(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  }, []);

  const pages = React.useMemo(() => [
    { id: 1, "title": "Home", "path": "/catalog" },
    { id: 2, "title": "APIs", "path": "/api-docs" },
    { id: 3, "title": "Docs", "path": "/docs" },
    { id: 4, "title": "Create", "path": "/create" },
    { id: 5, "title": "Tech Radar", "path": "/tech-radar" }
  ], []);


  return (
    <div>
      <AppBar position="static"
        sx={{
          backgroundColor: 'secondary.main',
          color: 'inherit',
          boxShadow: 0,
          borderBottom: 1,
          borderColor: 'divider',
        }} >
        <Box sx={{ width: "100%" }}>
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }} >
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Typography
                      variant="h6"
                      noWrap
                      component={Link}
                      to={page.path}
                      textAlign={'center'}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {page.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: "flex-start" }}>
              {pages.map((page, index) => (
                <Button key={index}>
                  <Typography
                    variant="h6"
                    noWrap
                    component={Link}
                    to={page.path}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {page.title}
                  </Typography>
                </Button>
              ))}
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: "flex-end", marginRight: "0px" }}>
              <IconButton
                color="inherit"
                aria-label="search"
              >
                <SidebarSearchModal />
              </IconButton>

              <IconButton
                color="inherit"
                aria-label="settings"
                component={Link}
                to="/settings"
              >
                <UserSettingsSignInAvatar />
              </IconButton>
            </Box>
          </Toolbar>

        </Box>
      </AppBar>
      {children}
    </div>
  );

};
