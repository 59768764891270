import GooperLightFont from '../assets/fonts/Gooper-Light.woff2';
import GooperLightItalicFont from '../assets/fonts/Gooper-Light-Italic.woff2';
import AbcOracleLightFont from '../assets/fonts/ABC-Oracle-Light.woff2';
import AbcOracleRegularFont from '../assets/fonts/ABC-Oracle-Regular.woff2';
import AbcOracleLightItalicFont from '../assets/fonts/ABC-Oracle-Light-Italic.woff2';
import AbcOracleRegularItalicFont from '../assets/fonts/ABC-Oracle-Regular-Italic.woff2';

const gooperLight = {
  fontFamily: 'Gooper',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Gooper-Light'),
    local('Gooper'), 
    url(${GooperLightFont}) format('woff2'), 
  `,
};

const GooperLightItalic = {
  fontFamily: 'Gooper',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
        local('Gooper-Light-Italic'),
        local('Gooper'), 
        url(${GooperLightItalicFont}) format('woff2'), 
    `,
};

const abcOracleLight = {
  fontFamily: 'ABC Oracle',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
        local('ABC-Oracle-Light'),
        local('ABC-Oracle'), 
        url(${AbcOracleLightFont}) format('woff2'), Font
    `,
};

const abcOracleRegular = {
  fontFamily: 'ABC Oracle',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
        local('ABC-Oracle-Regular'),
        local('ABC-Oracle'), 
        url(${AbcOracleRegularFont}) format('woff2'), 
    `,
};

const abcOracleLightItalic = {
  fontFamily: 'ABC Oracle',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
        local('ABC-Oracle-Light-Italic'),
        local('ABC-Oracle'), 
        url(${AbcOracleLightItalicFont}) format('woff2'), 
    `,
};

const abcOracleRegularItalic = {
  fontFamily: 'ABC Oracle',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
        local('ABC-Oracle-Regular-Italic'),
        local('ABC-Oracle'), 
        url(${AbcOracleRegularItalicFont}) format('woff2'), 
    `,
};

export const fonts = [
  gooperLight,
  GooperLightItalic,
  abcOracleLight,
  abcOracleRegular,
  abcOracleLightItalic,
  abcOracleRegularItalic,
];