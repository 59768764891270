export const black = '#000000';
export const cream = '#E7DDD1';
export const white = '#FFFFFF';
export const peach = '#FFB085';
export const yellow = '#FFED4D';
export const pink = '#FA50A3';
export const purple = '#D080E3';
export const green = '#2DFF80';
export const red = '#FF4B33';
export const blue = '#3EB0Ff';

/**
 * Lightens or darkens a color.
 *
 * @param {string} color - The color in hex format.
 * @param {number} amount - The amount to lighten or darken. Positive to lighten, negative to darken.
 * @returns {string} The new color in hex format.
 */
export function adjustColor(color: string, amount: number) {
  let usePound = false;

  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);

  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}